import companylogo1 from "../images/logo.jpg";
import { BsInstagram } from "react-icons/bs";
import { BiPhoneCall } from "react-icons/bi";
import { MdEmail } from "react-icons/md";
import { AiFillFacebook, AiOutlineArrowRight } from "react-icons/ai";
import { RiLinkedinBoxFill } from "react-icons/ri";
import { FaLocationDot } from "react-icons/fa6";
import {Link} from "react-router-dom";

let Footer = () => {
    return (
        <>
            <footer className="bg-gray-900">
                <div className="max-w-7xl mx-auto px-2 py-10 sm:px-6 lg:px-8">
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
                        <div>
                            <Link to='/' className="items-center mb-3"><img src={companylogo1} alt="Logo" /></Link>
                            <p className="mt-4 max-w-xs text-white text-justify">
                                With the latest technologies and a wide range of available materials, there really is no end of choice and quality in the manufacturing of Club Ink signage.
                            </p>
                            <p className="mt-8 text-xl font-semibold text-white">Follow Us</p>
                            <ul className="mt-2 flex gap-5">
                                <li>
                                    <Link to='#' className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-120 duration-300 flex items-center text-white hover:text-orange-500 border-lg text-2xl">
                                        <AiFillFacebook />
                                    </Link>
                                </li>
                                <li>
                                    <Link  to="https://www.instagram.com/club_ink_toronto/"
                                           target={"_blank"} className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-120 duration-300 flex items-center text-white hover:text-orange-500 border-lg text-2xl">
                                        <BsInstagram />
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://www.linkedin.com/company/club-ink/"
                                          target={"_blank"}
                                          className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-120 duration-300 flex items-center text-white hover:text-orange-500 border-lg text-2xl">
                                        <RiLinkedinBoxFill />
                                    </Link>
                                </li>
                            </ul>
                        </div>

                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:col-span-2 lg:grid-cols-3 gap-4">
                            <div>
                                <p className="text-xl font-semibold text-white">Quick Links</p>
                                <hr className="w-10 h-0.5 mt-4 bg-amber-500 border-0"></hr>
                                <ul className="mt-6 space-y-4 text-sm">
                                    <li>
                                        <Link to="/" className="footer-link">
                                            <AiOutlineArrowRight className="text-lg text-amber-500" /> Home
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#" className="footer-link">
                                            <AiOutlineArrowRight className="text-lg text-amber-500" /> About Us
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="mailto:sales@clubink.ca" className="footer-link">
                                            <AiOutlineArrowRight className="text-lg text-amber-500" /> Contact Us
                                        </Link>
                                    </li>
                                </ul>
                            </div>

                            <div>
                                <p className="text-xl font-semibold text-white">Services</p>
                                <hr className="w-10 h-0.5 mt-4 bg-amber-500 border-0"></hr>
                                <ul className="mt-6 space-y-4 text-sm">
                                    <li>
                                        <Link to="#" className="footer-link">
                                            <AiOutlineArrowRight className="text-lg text-amber-500" /> Installations & Strikes
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#" className="footer-link">
                                            <AiOutlineArrowRight className="text-lg text-amber-500" /> Maintenance
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#" className="footer-link">
                                            <AiOutlineArrowRight className="text-lg text-amber-500" /> Graffiti Removal
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#" className="footer-link">
                                            <AiOutlineArrowRight className="text-lg text-amber-500" /> Printing
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#" className="footer-link">
                                            <AiOutlineArrowRight className="text-lg text-amber-500" /> Design
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#" className="footer-link">
                                            <AiOutlineArrowRight className="text-lg text-amber-500" /> Builds
                                        </Link>
                                    </li>
                                </ul>
                            </div>

                            <div>
                                <p className="text-xl font-semibold text-white">Contact Us</p>
                                <hr className="w-10 h-0.5 mt-4 bg-amber-500 border-0"></hr>
                                <ul className="mt-6 space-y-4 text-sm">
                                    <li>
                                        <Link to="#" className="footer-link">
                                            <FaLocationDot className="text-xl text-amber-500" /> 301 Danforth Rd #1, Toronto, ON M1L 3X2, Canada
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="tel:+14166941996" className="footer-link">
                                            <BiPhoneCall className="text-lg text-amber-500" /> +1 (416) 694-1996
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="mailto:sales@clubink.ca" className="footer-link">
                                            <MdEmail className="text-lg text-amber-500" /> sales@clubink.ca
                                        </Link>
                                    </li>
                                    <li>
                                        <p className="text-xl font-semibold text-white">Service Hours</p>
                                    </li>
                                    <li>
                                        <p className="text-white">Monday – Friday: 7:30 AM – 5:00 PM</p>
                                    </li>
                                    <li>
                                        <p className="text-white">Saturday – Sunday: Closed</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <hr className="mt-8 border-[#424349]"/>
                    <div className="mt-5 text-sm lg:flex lg:justify-between">
                        <p className="text-white">&copy; Copyright 2024. All Rights Reserved By Club Ink</p>
                        <p className="text-white">Website Design & Developed By <Link target="_blank" to="https://softbuiltsolutions.com/" className="text-amber-500 transition hover:text-white">Soft Built Solutions</Link></p>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;
